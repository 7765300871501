import './css/NewsPreview.css'


function NewsPreview(props) {
    function truncateDescription(description, wordCount) {
        const words = description.split(' ');
        return description;
    }
    
    return (
        <div className="NewsPreview">
            <h2>{props.post.title} (publicerad {props.post.date})</h2>
            <p>{truncateDescription(props.post.description, 64)}</p>
            <h3>Skriven av <span className='Theme'>{props.post.author}</span></h3>
        </div>
    );
}

export default NewsPreview;
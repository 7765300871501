import './css/Error.css'

function Error() {
  return (
    <main className="Error-Content">
        <h1>404: Gudfadderiet vakar över dig, <span className='Theme'>N0llan</span>!</h1>
    </main>
  );
}

export default Error;